import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _903f2770 = () => interopDefault(import('../pages/acerca-de.vue' /* webpackChunkName: "pages/acerca-de" */))
const _71525efa = () => interopDefault(import('../pages/aviso-legal.vue' /* webpackChunkName: "pages/aviso-legal" */))
const _919d033e = () => interopDefault(import('../pages/ayuda.vue' /* webpackChunkName: "pages/ayuda" */))
const _51cc3d64 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _1d3a8715 = () => interopDefault(import('../pages/cambiar-contrasena.vue' /* webpackChunkName: "pages/cambiar-contrasena" */))
const _8f052638 = () => interopDefault(import('../views/CategoriesBridge.vue' /* webpackChunkName: "" */))
const _34752706 = () => interopDefault(import('../pages/cobee.vue' /* webpackChunkName: "pages/cobee" */))
const _0456b2e6 = () => interopDefault(import('../views/Homepage.vue' /* webpackChunkName: "" */))
const _2232d987 = () => interopDefault(import('../pages/compartir/index.vue' /* webpackChunkName: "pages/compartir/index" */))
const _2d2fd0b8 = () => interopDefault(import('../pages/contacto.vue' /* webpackChunkName: "pages/contacto" */))
const _953d8178 = () => interopDefault(import('../pages/contrasena-actualizada-exitosamente.vue' /* webpackChunkName: "pages/contrasena-actualizada-exitosamente" */))
const _9bf3ab94 = () => interopDefault(import('../pages/cookies.vue' /* webpackChunkName: "pages/cookies" */))
const _6ae894b5 = () => interopDefault(import('../pages/cupones/index.vue' /* webpackChunkName: "pages/cupones/index" */))
const _2894be3e = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _2d1d1e41 = () => interopDefault(import('../pages/dashboard/index/index.vue' /* webpackChunkName: "pages/dashboard/index/index" */))
const _45bcb4ac = () => interopDefault(import('../pages/dashboard/index/actividad.vue' /* webpackChunkName: "pages/dashboard/index/actividad" */))
const _1a532d2c = () => interopDefault(import('../pages/dashboard/index/ajustes.vue' /* webpackChunkName: "pages/dashboard/index/ajustes" */))
const _8249fdec = () => interopDefault(import('../pages/dashboard/index/chats.vue' /* webpackChunkName: "pages/dashboard/index/chats" */))
const _3c635e2c = () => interopDefault(import('../pages/dashboard/index/descuentos.vue' /* webpackChunkName: "pages/dashboard/index/descuentos" */))
const _5dc6365b = () => interopDefault(import('../pages/dashboard/index/guardados.vue' /* webpackChunkName: "pages/dashboard/index/guardados" */))
const _bcd12768 = () => interopDefault(import('../pages/dashboard/index/monedas.vue' /* webpackChunkName: "pages/dashboard/index/monedas" */))
const _662658d2 = () => interopDefault(import('../pages/dashboard/index/premios.vue' /* webpackChunkName: "pages/dashboard/index/premios" */))
const _59fde44c = () => interopDefault(import('../pages/dashboard/index/sorteos.vue' /* webpackChunkName: "pages/dashboard/index/sorteos" */))
const _138570e8 = () => interopDefault(import('../pages/eventos/index.vue' /* webpackChunkName: "pages/eventos/index" */))
const _03c19f34 = () => interopDefault(import('../pages/expirados.vue' /* webpackChunkName: "pages/expirados" */))
const _e9aa77b4 = () => interopDefault(import('../pages/extension.vue' /* webpackChunkName: "pages/extension" */))
const _7f9ef29c = () => interopDefault(import('../pages/extension-desinstalada.vue' /* webpackChunkName: "pages/extension-desinstalada" */))
const _10f776bd = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _2e70c457 = () => interopDefault(import('../pages/foros/index.vue' /* webpackChunkName: "pages/foros/index" */))
const _1cf68431 = () => interopDefault(import('../pages/gratis.vue' /* webpackChunkName: "pages/gratis" */))
const _78c367ca = () => interopDefault(import('../pages/inicio-sin-contrasena.vue' /* webpackChunkName: "pages/inicio-sin-contrasena" */))
const _2bfeb1d0 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _7b9cacfa = () => interopDefault(import('../views/InstallExtension.vue' /* webpackChunkName: "" */))
const _f5fd6112 = () => interopDefault(import('../pages/mensajeria/index.vue' /* webpackChunkName: "pages/mensajeria/index" */))
const _718ac31a = () => interopDefault(import('../pages/notificaciones.vue' /* webpackChunkName: "pages/notificaciones" */))
const _8e023aa2 = () => interopDefault(import('../pages/novedades/index.vue' /* webpackChunkName: "pages/novedades/index" */))
const _22832f73 = () => interopDefault(import('../pages/nueva-contrasena.vue' /* webpackChunkName: "pages/nueva-contrasena" */))
const _3cca5a4c = () => interopDefault(import('../pages/ofertas/index.vue' /* webpackChunkName: "pages/ofertas/index" */))
const _5cf91ea4 = () => interopDefault(import('../pages/olvide-mi-contrasena.vue' /* webpackChunkName: "pages/olvide-mi-contrasena" */))
const _4180eaf9 = () => interopDefault(import('../pages/politicas-de-privacidad.vue' /* webpackChunkName: "pages/politicas-de-privacidad" */))
const _855a4dae = () => interopDefault(import('../views/Preferences.vue' /* webpackChunkName: "" */))
const _25bd9d62 = () => interopDefault(import('../pages/prensa.vue' /* webpackChunkName: "pages/prensa" */))
const _df27e2c8 = () => interopDefault(import('../pages/registro.vue' /* webpackChunkName: "pages/registro" */))
const _71e896d4 = () => interopDefault(import('../pages/reglas-de-comunidad.vue' /* webpackChunkName: "pages/reglas-de-comunidad" */))
const _d76cbc5e = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _a907ba42 = () => interopDefault(import('../pages/sitemap/index.vue' /* webpackChunkName: "pages/sitemap/index" */))
const _4a9673bc = () => interopDefault(import('../pages/sitemap/index/index.vue' /* webpackChunkName: "pages/sitemap/index/index" */))
const _5c53a655 = () => interopDefault(import('../pages/sitemap/index/promociones/index.vue' /* webpackChunkName: "pages/sitemap/index/promociones/index" */))
const _e81c0c6a = () => interopDefault(import('../pages/sitemap/index/tiendas/index.vue' /* webpackChunkName: "pages/sitemap/index/tiendas/index" */))
const _5a9d050d = () => interopDefault(import('../pages/sitemap/index/promociones/_slug.vue' /* webpackChunkName: "pages/sitemap/index/promociones/_slug" */))
const _f49ff09e = () => interopDefault(import('../pages/subscribe.vue' /* webpackChunkName: "pages/subscribe" */))
const _54a77c10 = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _24389f73 = () => interopDefault(import('../pages/verificar-cuenta.vue' /* webpackChunkName: "pages/verificar-cuenta" */))
const _58444b33 = () => interopDefault(import('../pages/compartir/chat.vue' /* webpackChunkName: "pages/compartir/chat" */))
const _1233acd5 = () => interopDefault(import('../pages/compartir/cupon/index.vue' /* webpackChunkName: "pages/compartir/cupon/index" */))
const _f6cad580 = () => interopDefault(import('../pages/compartir/finalizado.vue' /* webpackChunkName: "pages/compartir/finalizado" */))
const _5c850a4f = () => interopDefault(import('../pages/compartir/oferta/index.vue' /* webpackChunkName: "pages/compartir/oferta/index" */))
const _23bf643a = () => interopDefault(import('../pages/compartir/sorteo.vue' /* webpackChunkName: "pages/compartir/sorteo" */))
const _22083b0c = () => interopDefault(import('../views/StoresBridge.vue' /* webpackChunkName: "" */))
const _65dc120a = () => interopDefault(import('../pages/compartir/cupon/crear.vue' /* webpackChunkName: "pages/compartir/cupon/crear" */))
const _9fa520f8 = () => interopDefault(import('../pages/compartir/oferta/crear.vue' /* webpackChunkName: "pages/compartir/oferta/crear" */))
const _158ee55e = () => interopDefault(import('../views/StoreMask.vue' /* webpackChunkName: "" */))
const _02dab71c = () => interopDefault(import('../pages/blog/_category/index.vue' /* webpackChunkName: "pages/blog/_category/index" */))
const _2925d2f2 = () => interopDefault(import('../views/DiscountDetails.vue' /* webpackChunkName: "" */))
const _4e398e16 = () => interopDefault(import('../pages/dashboard/_user.vue' /* webpackChunkName: "pages/dashboard/_user" */))
const _53ecac19 = () => interopDefault(import('../pages/dashboard/_user/index.vue' /* webpackChunkName: "pages/dashboard/_user/index" */))
const _3be19684 = () => interopDefault(import('../pages/dashboard/_user/actividad.vue' /* webpackChunkName: "pages/dashboard/_user/actividad" */))
const _34aae23c = () => interopDefault(import('../pages/dashboard/_user/chats.vue' /* webpackChunkName: "pages/dashboard/_user/chats" */))
const _0adab754 = () => interopDefault(import('../pages/dashboard/_user/descuentos.vue' /* webpackChunkName: "pages/dashboard/_user/descuentos" */))
const _11cecfa0 = () => interopDefault(import('../pages/eventos/_slug.vue' /* webpackChunkName: "pages/eventos/_slug" */))
const _2cba230f = () => interopDefault(import('../pages/foros/_slug.vue' /* webpackChunkName: "pages/foros/_slug" */))
const _1e295b6c = () => interopDefault(import('../views/DiscountMask.vue' /* webpackChunkName: "" */))
const _916f7d32 = () => interopDefault(import('../pages/novedades/_slug.vue' /* webpackChunkName: "pages/novedades/_slug" */))
const _250b48dd = () => interopDefault(import('../pages/blog/_category/_article.vue' /* webpackChunkName: "pages/blog/_category/_article" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/acerca-de",
    component: _903f2770,
    name: "acerca-de"
  }, {
    path: "/aviso-legal",
    component: _71525efa,
    name: "aviso-legal"
  }, {
    path: "/ayuda",
    component: _919d033e,
    name: "ayuda"
  }, {
    path: "/blog",
    component: _51cc3d64,
    name: "blog"
  }, {
    path: "/cambiar-contrasena",
    component: _1d3a8715,
    name: "cambiar-contrasena"
  }, {
    path: "/categorias",
    component: _8f052638,
    name: "categorias"
  }, {
    path: "/cobee",
    component: _34752706,
    name: "cobee"
  }, {
    path: "/comentados",
    component: _0456b2e6,
    name: "index-comentados"
  }, {
    path: "/compartir",
    component: _2232d987,
    name: "compartir"
  }, {
    path: "/contacto",
    component: _2d2fd0b8,
    name: "contacto"
  }, {
    path: "/contrasena-actualizada-exitosamente",
    component: _953d8178,
    name: "contrasena-actualizada-exitosamente"
  }, {
    path: "/cookies",
    component: _9bf3ab94,
    name: "cookies"
  }, {
    path: "/cupones",
    component: _6ae894b5,
    name: "cupones"
  }, {
    path: "/dashboard",
    component: _2894be3e,
    children: [{
      path: "",
      component: _2d1d1e41,
      name: "dashboard-index"
    }, {
      path: "actividad",
      component: _45bcb4ac,
      name: "dashboard-index-actividad"
    }, {
      path: "ajustes",
      component: _1a532d2c,
      name: "dashboard-index-ajustes"
    }, {
      path: "chats",
      component: _8249fdec,
      name: "dashboard-index-chats"
    }, {
      path: "descuentos",
      component: _3c635e2c,
      name: "dashboard-index-descuentos"
    }, {
      path: "guardados",
      component: _5dc6365b,
      name: "dashboard-index-guardados"
    }, {
      path: "monedas",
      component: _bcd12768,
      name: "dashboard-index-monedas"
    }, {
      path: "premios",
      component: _662658d2,
      name: "dashboard-index-premios"
    }, {
      path: "sorteos",
      component: _59fde44c,
      name: "dashboard-index-sorteos"
    }]
  }, {
    path: "/eventos",
    component: _138570e8,
    name: "eventos"
  }, {
    path: "/expirados",
    component: _03c19f34,
    name: "expirados"
  }, {
    path: "/extension",
    component: _e9aa77b4,
    name: "extension"
  }, {
    path: "/extension-desinstalada",
    component: _7f9ef29c,
    name: "extension-desinstalada"
  }, {
    path: "/faq",
    component: _10f776bd,
    name: "faq"
  }, {
    path: "/foros",
    component: _2e70c457,
    name: "foros"
  }, {
    path: "/gratis",
    component: _1cf68431,
    name: "gratis"
  }, {
    path: "/inicio-sin-contrasena",
    component: _78c367ca,
    name: "inicio-sin-contrasena"
  }, {
    path: "/login",
    component: _2bfeb1d0,
    name: "login"
  }, {
    path: "/lp",
    component: _7b9cacfa,
    name: "lp"
  }, {
    path: "/mensajeria",
    component: _f5fd6112,
    name: "mensajeria"
  }, {
    path: "/notificaciones",
    component: _718ac31a,
    name: "notificaciones"
  }, {
    path: "/novedades",
    component: _8e023aa2,
    name: "novedades"
  }, {
    path: "/nueva-contrasena",
    component: _22832f73,
    name: "nueva-contrasena"
  }, {
    path: "/nuevos",
    component: _0456b2e6,
    name: "index-nuevos"
  }, {
    path: "/ofertas",
    component: _3cca5a4c,
    name: "ofertas"
  }, {
    path: "/olvide-mi-contrasena",
    component: _5cf91ea4,
    name: "olvide-mi-contrasena"
  }, {
    path: "/politicas-de-privacidad",
    component: _4180eaf9,
    name: "politicas-de-privacidad"
  }, {
    path: "/preferencias",
    component: _855a4dae,
    name: "preferences"
  }, {
    path: "/prensa",
    component: _25bd9d62,
    name: "prensa"
  }, {
    path: "/registro",
    component: _df27e2c8,
    name: "registro"
  }, {
    path: "/reglas-de-comunidad",
    component: _71e896d4,
    name: "reglas-de-comunidad"
  }, {
    path: "/search",
    component: _d76cbc5e,
    name: "search"
  }, {
    path: "/sitemap",
    component: _a907ba42,
    children: [{
      path: "",
      component: _4a9673bc,
      name: "sitemap-index"
    }, {
      path: "promociones",
      component: _5c53a655,
      name: "sitemap-index-promociones"
    }, {
      path: "tiendas",
      component: _e81c0c6a,
      name: "sitemap-index-tiendas"
    }, {
      path: "promociones/:slug?",
      component: _5a9d050d,
      name: "sitemap-index-promociones-slug"
    }]
  }, {
    path: "/subscribe",
    component: _f49ff09e,
    name: "subscribe"
  }, {
    path: "/unsubscribe",
    component: _54a77c10,
    name: "unsubscribe"
  }, {
    path: "/verificar-cuenta",
    component: _24389f73,
    name: "verificar-cuenta"
  }, {
    path: "/compartir/chat",
    component: _58444b33,
    name: "compartir-chat"
  }, {
    path: "/compartir/cupon",
    component: _1233acd5,
    name: "compartir-cupon"
  }, {
    path: "/compartir/finalizado",
    component: _f6cad580,
    name: "compartir-finalizado"
  }, {
    path: "/compartir/oferta",
    component: _5c850a4f,
    name: "compartir-oferta"
  }, {
    path: "/compartir/sorteo",
    component: _23bf643a,
    name: "compartir-sorteo"
  }, {
    path: "/cupones-descuento/",
    component: _22083b0c,
    name: "marcas"
  }, {
    path: "/compartir/cupon/crear",
    component: _65dc120a,
    name: "compartir-cupon-crear"
  }, {
    path: "/compartir/oferta/crear",
    component: _9fa520f8,
    name: "compartir-oferta-crear"
  }, {
    path: "/",
    component: _0456b2e6,
    name: "index"
  }, {
    path: "/go/marcas/:id",
    component: _158ee55e,
    name: "go-store-slug"
  }, {
    path: "/blog/:category",
    component: _02dab71c,
    name: "blog-category"
  }, {
    path: "/categorias/:slug",
    component: _8f052638,
    name: "categorias-slug"
  }, {
    path: "/cupones-descuento/:slug",
    component: _22083b0c,
    name: "marcas-slug"
  }, {
    path: "/cupones/:slug",
    component: _2925d2f2,
    name: "cupones-slug"
  }, {
    path: "/dashboard/:user",
    component: _4e398e16,
    children: [{
      path: "",
      component: _53ecac19,
      name: "dashboard-user"
    }, {
      path: "actividad",
      component: _3be19684,
      name: "dashboard-user-actividad"
    }, {
      path: "chats",
      component: _34aae23c,
      name: "dashboard-user-chats"
    }, {
      path: "descuentos",
      component: _0adab754,
      name: "dashboard-user-descuentos"
    }]
  }, {
    path: "/eventos/:slug",
    component: _11cecfa0,
    name: "eventos-slug"
  }, {
    path: "/foros/:slug",
    component: _2cba230f,
    name: "foros-slug"
  }, {
    path: "/go/:id",
    component: _1e295b6c,
    name: "go-id"
  }, {
    path: "/goprize/:hash",
    component: _1e295b6c,
    name: "goprize-hash"
  }, {
    path: "/lp/:slug",
    component: _7b9cacfa,
    name: "lp-slug"
  }, {
    path: "/novedades/:slug",
    component: _916f7d32,
    name: "novedades-slug"
  }, {
    path: "/ofertas/:slug",
    component: _2925d2f2,
    name: "ofertas-slug"
  }, {
    path: "/open/:slug",
    component: _1e295b6c,
    name: "open-slug"
  }, {
    path: "/blog/:category/:article",
    component: _250b48dd,
    name: "blog-category-article"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
