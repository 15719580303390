import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=0b6df423&scoped=true&"
import script from "./Footer.vue?vue&type=script&lang=ts&"
export * from "./Footer.vue?vue&type=script&lang=ts&"
import style0 from "./Footer.vue?vue&type=style&index=0&id=0b6df423&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b6df423",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SidebarSocialNetworksLazy: require('/home/cleavr/www.jdescuentos.cl/releases/20240911124229529/components/sidebar/SocialNetworksLazy.vue').default,SidebarGiveMe: require('/home/cleavr/www.jdescuentos.cl/releases/20240911124229529/components/sidebar/GiveMe.vue').default,DropdownLocation: require('/home/cleavr/www.jdescuentos.cl/releases/20240911124229529/components/dropdown/Location.vue').default})
